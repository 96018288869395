import { CalloutCard } from '@thd-olt-component-react/callout_card';
import { CapabilityCard } from '@thd-olt-component-react/capability_card';
import { ComponentSelector, Slot } from '@thd-olt-component-react/component-selector';
import { ContentAccordion } from '@thd-olt-component-react/content-accordion';
import { ContentSection } from '@thd-olt-component-react/content-section';
import { Hero, HeroPersonalized } from '@thd-olt-component-react/hero';
import { Endcap } from '@thd-olt-component-react/endcap';
import { MedioInline, PiccolaPencil, MedioComposableBanner, PiccolaComposableBanner } from '@thd-olt-component-react/internal-marketing-banner';
import { NewspaperModule } from '@thd-olt-component-react/newspaper-module';
import { RelatedSearch } from '@thd-olt-component-react/related-search';
import { Resource } from '@thd-olt-component-react/resource';
import { ContentGalleryCard } from '@thd-olt-component-react/content-gallery-card';
import { SpecialBuyBanner } from '@thd-olt-component-react/special-buy-banner';
import { EmtDescription } from '@thd-olt-component-react/emt-generated-content';
import { EmtLinks } from '@thd-olt-component-react/emt-links';
import { SideNavigation } from '@thd-olt-component-react/side-navigation';
import { BasicSponsoredBanner } from '@thd-olt-component-react/sponsored-content';
import { BasicPLA } from '@thd-olt-component-react/retail-media-pla-sponsored-placements';
import { Spotlight, SpotlightContainer } from '@thd-olt-component-react/spotlight';
import { PromoVisualNavigation, VisualNavigation } from '@thd-olt-component-react/visual-navigation';
import { ProductShelf } from '@thd-olt-component-react/product-shelf';
import { educationalLayoutFragment, inspirationalLayoutFragment, navigationalLayoutFragment, universalLayoutFragment } from '../templates/dataModel';

const buildComponentMapperObj = () => {
  const contentfulTypeToComponentMap = {
    CalloutCard,
    CapabilityCard,
    ComponentSelector,
    ContentAccordion,
    Description_New: EmtDescription,
    Gallery: ContentGalleryCard,
    Hero,
    HeroPersonalized,
    HeroCarousel: ContentSection,
    MedioInline,
    MedioComposableBanner,
    PiccolaComposableBanner,
    Newspaper: NewspaperModule,
    PiccolaPencil,
    ProductShelf,
    RelatedSearchesAndProducts: RelatedSearch,
    Resource,
    Section: ContentSection,
    SeoLinks: EmtLinks,
    SideNavigation,
    Slot,
    SponsoredBottomCarousel: BasicPLA,
    SponsoredMiddleBanner: BasicSponsoredBanner,
    SponsoredSkyscraperBanner: BasicSponsoredBanner,
    SponsoredTopBanner: BasicSponsoredBanner,
    Spotlight,
    SpotlightContainer,
    VisualNavigation,
    PromoVisualNavigation,
    SpecialBuyOfTheDay: SpecialBuyBanner,
    EndCap: Endcap
  };

  const sponsoredContentContentfulKeys = [
    'sponsoredBottomCarousel',
    'sponsoredMiddleBanner',
    'sponsoredSkyscraperBanner',
    'sponsoredTopBanner',
  ];

  const filterContentfulKeys = (key) => {
    if (key === 'id' || key === 'breadcrumbs' || key.startsWith('sponsored')) {
      return false;
    }
    return true;
  };

  const filterSponsoredKeys = (key) => {
    if (sponsoredContentContentfulKeys.includes(key)) {
      return true;
    }
    return false;
  };

  // Data model already define what keys are expected for each template, so leverage that to define array of expected
  // keys from Contentful per layout type.
  const educationalContentfulKeys = Object.keys(educationalLayoutFragment._children).filter((key) => filterContentfulKeys(key));
  const inspirationalContentfulKeys = Object.keys(inspirationalLayoutFragment._children).filter((key) => filterContentfulKeys(key));
  const navigationalContentfulKeys = Object.keys(navigationalLayoutFragment._children).filter((key) => filterContentfulKeys(key));
  const universalContentfulKeys = Object.keys(universalLayoutFragment._children).filter((key) => filterContentfulKeys(key));

  const educationalSponsoredKeys = Object.keys(educationalLayoutFragment._children).filter((key) => filterSponsoredKeys(key));
  const inspirationalSponsoredKeys = Object.keys(inspirationalLayoutFragment._children).filter((key) => filterSponsoredKeys(key));
  const navigationalSponsoredKeys = Object.keys(navigationalLayoutFragment._children).filter((key) => filterSponsoredKeys(key));
  const universalSponsoredKeys = Object.keys(universalLayoutFragment._children).filter((key) => filterSponsoredKeys(key));

  // Defines exactly what keys (components and sections to render) we expect to see in the Contentful JSON object for
  // each layout.
  const layoutContentfulKeys = {
    educational: educationalContentfulKeys,
    inspirational: inspirationalContentfulKeys,
    navigational: navigationalContentfulKeys,
    universal: universalContentfulKeys
  };
  const layoutSponsoredKeys = {
    educational: educationalSponsoredKeys,
    inspirational: inspirationalSponsoredKeys,
    navigational: navigationalSponsoredKeys,
    universal: universalSponsoredKeys
  };

  return {
    getComponentMap: () => {
      return contentfulTypeToComponentMap;
    },
    getComponent: (contentfulType) => {
      return contentfulTypeToComponentMap[contentfulType];
    },
    getMainContentContentfulKeys: (layout) => {
      return layoutContentfulKeys[layout];
    },
    getSponsoredContentContentfulKeys: (layout) => {
      return layoutSponsoredKeys[layout];
    },
    getLayoutContentfulKeys: () => {
      return layoutContentfulKeys;
    },
    getLayoutSponsoredKeys: () => {
      return layoutSponsoredKeys;
    },
    getContentfulTypeToComponentMap: () => {
      return contentfulTypeToComponentMap;
    }
  };
};

export const componentMapper = buildComponentMapperObj();
