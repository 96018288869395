/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable @mizdra/layout-shift/require-size-attributes */
import React, { useEffect } from 'react';
import { bool, number, shape, string } from 'prop-types';
import {
  arrayOf,
  customType,
  namedFragment,
  params,
  shape as shapeType,
  string as stringType,
  typename,
  useDataModel,
} from '@thd-nucleus/data-sources';
import { SpotlightRenderer } from './SpotlightRenderer';

const Spotlight = ({
  componentId,
  componentClass = '',
  componentPosition = 1,
  showDescription = false,
  useB2bStyles = false,
  proAnalyticsData = {},
  lazyLoad = false,
  orientation = 'vertical',
  fontWeight = 'display',
  shouldUseContentPlayer = false,
  cmMmcParam = '' // adobe's Campaign Management Multi-Media Channel parameter to be passed to Citi,
  // so that we can track credit card campaigns through application completions
}) => {
  useEffect(() => {
    LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('spotlight.ready');
  }, []);

  const { data } = useDataModel('component', {
    variables: {
      id: componentId,
      componentClass,
    },
  });

  let {
    richTextContent,
    linkList,
  } = data?.component || {};

  return (
    <SpotlightRenderer
      componentId={componentId}
      componentClass={componentClass}
      componentPosition={componentPosition}
      data={data?.component || {}}
      lazyLoad={lazyLoad}
      listOfLinks={linkList}
      orientation={orientation}
      proAnalyticsData={proAnalyticsData}
      richText={richTextContent}
      showDescription={showDescription}
      useB2bStyles={useB2bStyles}
      fontWeight={fontWeight}
      cmMmcParam={cmMmcParam}
      shouldUseContentPlayer={shouldUseContentPlayer}
    />
  );
};

Spotlight.displayName = 'Spotlight';

// Do not touch
const DamMediaFragment = namedFragment({
  inline: false,
  fragmentType: 'DamMedia',
  fragmentAlias: 'DamMediaV1'
}).shape({
  damContentSelector: shapeType({
    assetData: arrayOf(
      shapeType({
        selectedImageUrl: stringType()
      })
    )
  }),
  damDownloadedContent: shapeType({
    url: stringType()
  }),
  id: stringType(),
  __typename: typename('DamMedia')
});

Spotlight.propTypes = {
  componentId: string.isRequired,
  componentPosition: number,
  componentClass: string,
  showDescription: bool,
  useB2bStyles: bool,
  proAnalyticsData: shape({
    isB2B: bool,
    templates: string,
  }),
  lazyLoad: bool,
  orientation: string,
  fontWeight: string,
  cmMmcParam: string,
  shouldUseContentPlayer: bool,
};

Spotlight.dataModel = {
  component: params({
    id: stringType().isRequired(),
    componentClass: customType('ComponentClass').enum(['Spotlight']).isRequired(),
  }).shape({
    Spotlight: namedFragment({ inline: true, fragmentType: 'Spotlight' }).shape({
      id: stringType(),
      title: stringType(),
      altText: stringType(),
      cta: stringType(),
      link: stringType(),
      componentName: stringType(),
      description: stringType(),
      richTextContent: stringType(),
      proAnalyticsCampaign: stringType(),
      proAnalyticsComponent: stringType(),
      linkList: arrayOf(shapeType({
        label: stringType(),
        href: stringType(),
      })),
      videoUrl: stringType(),
      previewImage: DamMediaFragment,
      __typename: typename('Spotlight')
    }),
  }),
};

export { Spotlight };
